import SendbirdChat, { MetaData, User, UserEventHandler } from "@sendbird/chat";
import config from "@/config";
import {
  GroupChannel,
  GroupChannelCollection,
  GroupChannelCollectionParams,
  GroupChannelCreateParams,
  GroupChannelFilter,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelModule,
  MessageCollection,
  MessageFilter,
  MyMemberStateFilter,
  SendbirdGroupChat,
} from "@sendbird/chat/groupChannel";
import {
  MessageHandler,
  UserMessageCreateParams,
} from "@sendbird/chat/message";
import { UserType } from "@/types/user";

class SendbirdService {
  constructor() {}
  private _unreadMessagesCallback: Function | undefined;

  private sb = SendbirdChat.init({
    appId: config("sendbirdAppId"),
    modules: [new GroupChannelModule()],
  }) as SendbirdGroupChat;
  public isConnected = false;
  private groupChannelListQuery: GroupChannelListQuery | null = null;

  public async connect(
    userId: string,
    unreadMessagesCallback: Function,
  ): Promise<User | void> {
    try {
      const user = await this.sb.connect(userId);
      const unreadMessages =
        await this.sb.groupChannel.getTotalUnreadMessageCount();
      unreadMessagesCallback(unreadMessages);
      this._unreadMessagesCallback = unreadMessagesCallback;

      const userEventHandler = new UserEventHandler({
        onTotalUnreadMessageCountUpdated: (
          totalCount: number,
          countByCustomType: object,
        ) => {
          if (this._unreadMessagesCallback)
            this._unreadMessagesCallback(totalCount);
        },
      });
      this.sb.addUserEventHandler(
        "UNREAD_MESSAGES_COUNT_UPDATED",
        userEventHandler,
      );
      this.isConnected = true;
    } catch (error) {
      console.log(error);
    }
  }

  public fillUserData(nickname: string, profileUrl: string): Promise<User> {
    return this.sb.updateCurrentUserInfo({
      nickname,
      profileUrl,
    });
  }

  public createChannel = async (
    userIds: string[],
  ): Promise<GroupChannel | undefined> => {
    try {
      const params: GroupChannelCreateParams = {
        isPublic: false,
        isEphemeral: false,
        isDistinct: true,
        isSuper: false,
        invitedUserIds: userIds,
      };

      return await this.sb.groupChannel.createChannel(params);
    } catch (error) {
      console.error(error);
    }
  };

  public updateUserName = async (nickname = ""): Promise<User | null> => {
    const currentUser = this.sb.currentUser as any;

    try {
      return this.sb.updateCurrentUserInfo({
        nickname: nickname.trim(),
        profileUrl: currentUser.profileUrl,
      });
    } catch (error) {
      return null;
    }
  };

  public updateUserPicture = async (profileUrl = ""): Promise<User | null> => {
    const currentUser = this.sb.currentUser;

    try {
      return this.sb.updateCurrentUserInfo({
        nickname: currentUser.nickname,
        profileUrl,
      });
    } catch (e) {
      return null;
    }
  };

  public getChannel = async (url: string): Promise<GroupChannel | void> => {
    try {
      return this.sb.groupChannel.getChannel(url);
    } catch (e) {
      console.error(e);
    }
  };

  public sendMessage = async (
    channel: GroupChannel,
    value: string,
    onMsgSent: MessageHandler,
    userType: UserType,
  ): Promise<any> => {
    try {
      const params: UserMessageCreateParams = {
        message: value,
        data: JSON.stringify({ isManualMessage: true }),
      };
      if (userType === UserType.pro) {
        const data: MetaData = {
          proChatted: "true",
        };
        const metaData = await channel.getMetaData(["proChatted"]);
        if (!metaData || Object.keys(metaData).length === 0) {
          await channel.createMetaData(data);
        }
      }
      channel?.sendUserMessage(params).onSucceeded(onMsgSent);
    } catch (error) {
      console.error(error);
    }
  };

  public initGroupChannelCollection = async (
    filterNickname?: string,
  ): Promise<GroupChannelCollection> => {
    const groupCahnnelFilter: GroupChannelFilter = new GroupChannelFilter();
    groupCahnnelFilter.includeEmpty = false;
    groupCahnnelFilter.myMemberStateFilter = MyMemberStateFilter.JOINED;
    if (filterNickname?.length) {
      groupCahnnelFilter.nicknameContainsFilter = filterNickname;
    }
    const params: GroupChannelCollectionParams = {
      filter: groupCahnnelFilter,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      limit: 30,
    };
    return this.sb.groupChannel.createGroupChannelCollection(params);
  };

  public loadNextPage = async (): Promise<GroupChannel[]> => {
    if (this.groupChannelListQuery && this.groupChannelListQuery.hasNext) {
      return this.groupChannelListQuery.next();
    }
    return [];
  };

  public initMessagesCollection = async (
    url: string,
  ): Promise<MessageCollection | void> => {
    try {
      const groupChannel = await this.sb.groupChannel.getChannel(url);
      const filter: MessageFilter = new MessageFilter();
      const limit = 30;
      const startingPoint: number = Date.now();
      const collection: MessageCollection =
        groupChannel.createMessageCollection({
          filter,
          limit,
          startingPoint,
        });
      return collection;
    } catch (error) {
      console.error(error);
    }
  };

  public disconnect = async (): Promise<void> => {
    try {
      await this.sb.disconnect();
    } catch (error) {
      console.error(error);
    }
  };
}

export default SendbirdService;
