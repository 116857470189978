import SendbirdService from "@/services/sendbird-service";
import { defineStore } from "pinia";
import { useUserStore } from "@/store/user";
import { GroupChannelCollection } from "@sendbird/chat/groupChannel";
import { Exception } from "@/utils/error";
import { UserEventHandler } from "@sendbird/chat";

const userStore = useUserStore();

export const useChatStore = defineStore("chat", {
  state: () => ({
    groupChannelCollection: {} as GroupChannelCollection,
    userEventHandler: {} as UserEventHandler,
    hasUnreadMessages: false,
    sendbirdService: new SendbirdService(),
    initialized: false,
    pendingMethod: undefined as Function | undefined,
  }),
  actions: {
    async getSendbirdService() {
      return this.sendbirdService;
    },

    async getChannelById(userId: string) {
      const channel = await (await this.getSendbirdService())!.createChannel([
        userStore.userData._id,
        userId as string,
      ]);
      return channel;
    },
    /**
     * Checks if the last message of the chat conversation with
     * the user with [userId] is an automated message generated
     * by a feedback request
     * @param userId
     * returns a boolean and the order id that generated that message
     */
    async isLastMessageFeedbackRequest(
      userId: string,
    ): Promise<[boolean, string]> {
      const channel = await this.getChannelById(userId);
      if (!channel)
        throw new Exception(
          "SendbirdChannelNotFound",
          "A chat message with that user was not found",
        );
      try {
        // @ts-ignore
        const parsed = JSON.parse(channel.lastMessage.message);
        if (parsed && parsed.length) {
          const orderId = parsed[parsed.length - 1].split("?orderId=")[1];
          const firstRow = parsed[0] as string;
          if (firstRow.indexOf("Written Feedback Request") > -1)
            return [true, orderId];
          if (firstRow.indexOf("Audio Feedback Request") > -1)
            return [true, orderId];
          if (firstRow.indexOf("Video Feedback Request") > -1)
            return [true, orderId];
        }
      } catch (e) {
        return [false, ""];
      }
      return [false, ""];
    },

    async initChatService() {
      try {
        this.sendbirdService = new SendbirdService();
        const sb = await this.sendbirdService.connect(
          userStore.userData._id,
          this.unreadMessagesCallback,
        );

        const { stageName, firstName, lastName, profilePictureUrl } =
          userStore.userData || {};
        const name =
          stageName || [firstName, lastName].filter(Boolean).join(" ");

        await this.sendbirdService.fillUserData(name, profilePictureUrl || "");
        this.initialized = true;
        if (this.pendingMethod) {
          (this.pendingMethod as Function)();
        }
      } catch (error) {
        console.error(error);
      }
    },

    unreadMessagesCallback(messages: number) {
      this.hasUnreadMessages = messages > 0;
    },
  },
});
